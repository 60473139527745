.historyDetailsContainer {
  padding: 20px 18px;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  min-height: 500px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .historyDetailsContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}

.historyDetailsContainer .titleContainer{
  margin-bottom: 30px;
}

.historyDetailsContainer .detailsList{
  margin-bottom: 12px;
}

.historyDetailsContainer .detailsList .title{
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}

.historyDetailsContainer .detailsList .addressCopy{
display: flex;
}

.historyDetailsContainer .detailsList .addressCopy .copyImage{
  cursor: pointer;
  width:32px;
}

.historyDetailsContainer.detailsList .title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
}

.historyDetailsContainer .detailsContent .detailsList .tips{
  font-size: 14px;
  font-weight: 500;
}

.historyDetailsContainer .detailsList .addressCopy .tips{
  flex:1;
  line-height: 32px;
  overflow:hidden; 
  text-overflow:ellipsis;
  white-space:nowrap;
}
.historyDetailsContainer .detailsList .tipsLink{
  color:#0045ff;
  cursor: pointer;
}

.historyDetailsContainer .bottomTips{
  font-size: 12px;
  font-weight: 500;
  opacity: 0.8;
  margin-bottom: 12px;
}