.deposit_bank_msg_container {
    display: flex;
    width: 100%;
    border-radius: 8px;
    padding: 20px 30px;
    margin: 0px 0 20px;
    background: rgba(0, 69, 255, 0.03);
  
  }
  .deposit_bank_msg_container_left {
    display: flex;
    flex: 1;
    flex-direction: column;
  
  }
  .deposit_bank_msg_container_line {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 10px 0;
  
  }
  .line_left {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }
  .line_right {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: right;
  
  }
  .info_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 10px;
  }
  .copyBtn {
    margin-left: 10px;
  }
  .g-order {
    cursor: pointer;
    color: #0045ff;
  }
  .attention_tips {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff8e00;
    margin: 12px 0 40px;
  }
  .attention_tips_tips {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
  }
  .waitTips {
    margin-top: 48px;
    margin-bottom: 48px;
    color: #0045ff;
  }