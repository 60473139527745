.airContainer {
  display: flex;
  width: 375px;
  max-width: 100%;
  margin: auto;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 40px 40px 40px rgb(0 69 255 / 6%);
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .airContainer-embedded {
    box-shadow: none;
  }
}
.payAirContainer {
  padding: 30px;
}

.payAirContainer .topSty {
  display: flex;
  justify-content: space-between;
}

.payAirContainer .topSty .topStyRightContainer {
  cursor: pointer;
}

.payAirContainer .topSty .topStyRightContainer img {
  height: 20px;
}

.payAirContainer .cardType {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 320px;
}
.payAirContainer .airBottomBorder {
  margin-bottom: 20px;
}
.cardTypeLeft {
  display: flex;
}
.cardTypeLeft span {
  font-size: 16px;
  margin-left: 10px;
  align-items: center;
  display: flex;
}
.cardTypeRight img {
  height: 18px;
  margin-left: 5px;
}
.field-container {
  margin-top: 10px;
}
.field-label {
  color: rgb(84, 84, 94);
  font-size: 14px;
  font-weight: 400;
}
.field-container .am-list-item.am-input-item {
  height: 32px;
  margin-top: 8px;
  border: 1px solid #ebecf0;
  border-radius: 5px;
  padding: 0 15px;
}
/* .field-container .am-list-item.am-input-item:hover {
  border: 1px solid;
} */
.field-container .am-list-item.am-input-item:focus-within {
  border: 1px solid #0045ff !important;
}
.airInput {
  padding: 5px 10px;
  margin-top: 8px;
  height: 38px;
  border: 1px solid #ebecf0;
  border-radius: 5px;
}
.airInputFocus {
  padding: 5px 10px;
  margin-top: 8px;
  height: 38px;
  border: 1px solid #0045ff;
  border-radius: 5px;
}
.field-container .airInput:hover {
  border: 1px solid;
}
.airBtn {
  margin-top: 20px;
  color: #fff !important;
  cursor: pointer;
}
.billing {
  margin: 0px 0px 4px;
  font-weight: 700;
  line-height: 1.5;
  font-size: 24px;
  color: rgb(26, 29, 33);
  margin-top: 16px;
}
.country-select-air {
  width: 100%;
  height: 40px;
  margin-top: 8px;
  border: 1px solid rgba(64, 71, 214, 0.06);
  border-radius: 5px;
  padding: 0 15px;
}
.country-select-air:hover {
  width: 100%;
  height: 40px;
  margin-top: 8px;
  border: 1px solid;
  border-radius: 5px;
}
.country-select-air:focus {
  width: 100%;
  height: 40px;
  margin-top: 16px;
  border: 1px solid #0045ff;
  border-radius: 5px;
}
.errorTips {
  color: red;
  font-size: 10px;
  margin-top: 8px;
}
#agree {
  /* margin-bottom: 20px; */
}
.agreeLabel {
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
}
.initBillingContainer {
  height: 0;
  overflow: hidden;
}
.billingContainer {
  overflow: hidden;
  height: 760px;
  animation: show 1s;
}
.hideBillingContainer {
  height: 0;
  overflow: hidden;
  animation: hide 1s;
}
@keyframes show {
  from {
    height: 0px;
  }
  to {
    height: 760px;
  }
}
@keyframes hide {
  from {
    height: 760px;
  }
  to {
    height: 0px;
  }
}
