.threeDS_container {
  width: 365px;
  max-width: 100%;
  min-height:420px;
  margin: auto;
  border-radius: 20px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
  position: relative;
  justify-content: center;
    display: flex;
    align-items: center;
}
@media screen and (max-width: 750px) {
  .threeDS_container-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}
