.FastBuyAddressContainer {
  padding: 20px 15px;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 220px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .FastBuyAddressContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}

.FastBuyAddressContainer .titleATips {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  opacity: 0.6;
}

.FastBuyAddressContainer .wrapper-left-item {
  margin-bottom: 20px;
}

.FastBuyAddressContainer .select-container .ant-input-affix-wrapper-lg {
  border-radius: 15px;
  padding: 0px 11px;
}

.FastBuyAddressContainer .select-container .ant-input-lg {
  font-size: 14px;
  line-height: 30px;
  border-radius: 10px;
  height: 40px;
}

.FastBuyAddressContainer .select-container .afterImg {
  height: 24px;
}

/* .FastBuyAddressContainer .select-container .ant-input:hover {
  border-color: #0045ff;
  border-right-width: 1px;
} */

/* .FastBuyAddressContainer .select-container .ant-input:focus, .ant-input-focused{
  border-color: #0045ff;
  border-right-width: 1px;
} */
.FastBuyAddressContainer .wrapper-left-item-last .rateTips {
  color: #000;
  font-size: 12px;
}

.FastBuyAddressContainer .wrapper-left-item-last .rateTips .rateTipsTitle {
  opacity: 0.3;
}

.FastBuyAddressContainer .wrapper-left-item-last .rateTipsLeft {
  color: #000;
  font-size: 14px;
  text-align: left;
  margin-top: 20px;
  background: rgba(230, 154, 58, 0.12);
  display: flex;
  border-radius: 5px;
  padding: 10px;
}

.FastBuyAddressContainer .wrapper-left-item-last .rateTipsLeft img {
  width: 24px;
  height: 24px;
}

.FastBuyAddressContainer
  .wrapper-left-item-last
  .rateTipsLeft
  .rateTipsTitleLeft {
  opacity: 0.8;
  margin-left: 4px;
}

.FastBuyAddressContainer .wrapper-left-item .select-label {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
}

.FastBuyAddressContainer .wrapper-left-item .buyWarning {
  background-color: #fef3f2;
  border-radius: 15px;
  padding: 12px;
  margin: 30px auto;
}

.FastBuyAddressContainer .wrapper-left-item .buyWarning .warningTop {
  font-size: 20px;
  color: #bc2d1a;
  font-weight: 500;
  margin-bottom: 20px;
  vertical-align: middle;
}

.FastBuyAddressContainer .wrapper-left-item .buyWarning .warningTop img {
  width: 22px;
  vertical-align: middle;
  margin-right: 12px;
}

.FastBuyAddressContainer .wrapper-left-item .buyWarning .warningBottom {
  font-size: 14px;
  font-weight: 400;
}

.FastBuyAddressContainer .addressTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
  height: 20px;
  margin-bottom: 0;
}

.FastBuyAddressContainer .verConfirm {
  cursor: pointer;
  color: #fff;
  background-color: #0045ff;
  width: 100%;
}

.FastBuyAddressContainer .goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

/* .FastBuyAddressContainer .ant-btn-primary{
  background-color: #0045ff;
} */

.FastBuyAddressContainer .goToBtnDisabled {
  border-radius: 8px;
  background-color: #f5f5f5;
  height: 48px;
  width: 100%;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
