.walletContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}

@media screen and (max-width: 750px) {
  .walletContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}

.walletContainer .titleTips{
  margin: 10px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.walletContainer .wallet-list-container{
  background: rgba(0, 69, 255, 0.03);
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}

.walletContainer .wallet-list-container .top-title{
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.walletContainer .wallet-list-container .top-title .top-title-top{
  display: flex;
  align-items: center;
}

.walletContainer .wallet-list-container .top-title .top-title-top img{
  width:24px;
  margin-right: 4px;
}

.walletContainer .wallet-list-container .top-value{
  display: flex;
  justify-content: space-between;
  font-weight: 400;
 font-size: 14px;
 line-height: 16px;
 color: rgba(0, 0, 0, 0.3);
 margin-top: 15px;
}

.walletContainer .listContent {
  height: 320px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: auto;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.walletContainer .titleContainer .titleContainerLeft {
  max-width: 280px;
  overflow: hidden;
}

.walletContainer .listContainer {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  cursor: pointer;
  padding: 0 18px;
}

.walletContainer .listContainer:hover {
  background-color: rgba(64, 71, 214, 0.06);
}

.walletContainer .listContainer .listLeft {
  display: flex;
  font-size: 0;
}

.walletContainer .listContainer .listLeft img {
  width: 24px;
  vertical-align: middle;
  margin-right: 8px;
}

.walletContainer .listContainer .listLeft .listLeftTitle {
  display: flex;
  flex-direction: column;
}

.walletContainer .listContainer .listLeft .listLeftTitle .listLeftTitleTop {
  font-size: 14px;
  font-weight: 500;
}

.walletContainer .listContainer .listLeft .listLeftTitle .listLeftTitleBottom {
  font-size: 10px;
  opacity: 0.5;
}

.walletContainer .listContainer .listLeft span {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle;
}

.walletContainer .listContainer .listRight img {
  width: 24px;
  vertical-align: middle;
}

.walletContainer .pageSty {
  text-align: end;
}