.addCardContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}

@media screen and (max-width: 750px) {
  .addCardContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}

.addCardContainer .stepContainer {
  margin-top: 24px;
  padding: 24px 4px;
  background-color: #fafafa;
  border-radius: 12px;
}

.addCardContainer .inputContainer{
  margin-top: 12px;
}

.addCardContainer .inputContainerLast{
  margin-bottom: 24px;
}

.addCardContainer .inputContainer .label{
  margin-bottom: 10px;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.addCardContainer .inputContainer .ant-select {
  width: 100%;
}
.addCardContainer .inputContainer .ant-select .ant-select-selector{
  height: 48px;
  border-radius: 10px;
}

.addCardContainer .inputContainer .ant-select .ant-select-selector .ant-select-selection-item{
  line-height: 48px;
}

.addCardContainer .inputContainer .uploadBtn {
  margin-left: 10px;
  border-radius: 4px;
  font-size: 0;
  text-align: center;
  background: #0045ff;
  padding: 0 4px;
  color: #fff;
}

.addCardContainer .inputContainer .uploadBtn span {
  font-size: 14px;
}

.addCardContainer .inputContainer .uploadBtn img {
  height: 24px;
  margin-left: 10px;
}

.addCardContainer .uploadContainer {
  display: flex;
}

.addCardContainer .uploadContainer .needsclick {
  cursor: pointer;
}

.addCardContainer .uploadTips {
  margin: 10px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.addCardContainer .idCardContainer {
  height: 120px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  text-align: center;
}

.addCardContainer .idCardContainer img {
  height: 100px;
  margin: 10px 0;
}

.addCardContainer  .addContainer .goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  margin: 10px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.addCardContainer  .addContainer .goToBtnNext{
  margin-top: 0px;
}

.addCardContainer input::-webkit-outer-spin-button,
.addCardContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.addCardContainer input[type='number'] {
  -moz-appearance: textfield;
}

.addCardContainer .checkContainer{
  display: flex;
  margin-top: 12px;
  margin-bottom: 10px;
  color: #000;
  font-size: 12px;
  margin-top: 36px;
}
.addCardContainer .checkContainer .checkSty{
  margin-right: 8px;
}

.addCardContainer .showEmailTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
  margin-bottom: 0;
}

.addCardContainer .showEmailTips .showEmailTipsContainer{
  display: flex;
  align-items: center;
}

.addCardContainer .showEmailTips img{
  width: 18px;
  margin-right: 4px;
}
.addCardContainer .showEmailTips span{
  line-height: 14px;
}