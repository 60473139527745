.currencyContainer-list-pay {
  width: 365px;
  max-width: 100%;
  margin: auto;
  border-radius: 20px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
  position: relative;
  height: 784px;
  overflow: hidden;
}
@media screen and (max-width: 750px) {
  .currencyContainer-list-pay-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}
.currencyContainer {
  padding-bottom: 20px;
  max-width: 375px;
  margin: 0 auto 20px;
  border-radius: 20px;
  background-color: #fff;
}
.listContainer {
  position: relative;
}
.listContainer .am-tabs-pane-wrap {
  overflow-y: visible;
}
.payCenter {
  position: relative;
}
.codeScan {
  text-align: center;
  padding: 12.5px;
  border: 1px solid rgba(0, 69, 255, 0.3);
  margin: auto;
  border-radius: 10px;
  margin-bottom: 2px;
}
.payTopLeft {
  display: flex;
  align-items: center;
}
.topLeft {
  font-size: 0;
}
.topLeftImg {
  width: 25px;
  height: 25px;
  vertical-align: bottom;
  margin-right: 10px;
}
.topLeft .topLeftTextTitle {
  font-size: 14px;
}
.topLeft .topLeftText {
  font-size: 16px;
  font-weight: bold;
}
.address {
  margin-bottom: 20px;
}
.toPayTips {
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.6);
}
.toPayTipsWeight {
  color: #0045ff;
  text-decoration: underline;
  cursor: pointer;
}
.confirmPayBtnBottom {
  cursor: pointer;
  color: #fff;
  background-color: #0045ff;
  margin-top: 20px;
}
.titleImg {
  height: 25px;
}
.titleSty {
  margin-left: 5px;
  font-size: 12px;
}
.addressContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin: 10px 1px 0;
  padding: 10px;
  border: 1px solid #0045ff;
  border-radius: 5px;
  background: #fafafa;
  border-radius: 4px;
}
.copySty {
  font-weight: bold;
  margin-top: 10px;
}
.copyWeight {
  font-weight: bold;
  overflow: hidden;
  line-height: 32px;
}
.tipWeight {
  font-weight: bold;
  margin-right: 5px;
}
.tipReadMe {
  font-size: 12px;
  color: #0045ff;
}
.buyCoin {
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  font-weight: 600;
}
.buyCoinTitle {
  font-weight: 600;
  color: #0045ff;
  font-size: 12px;
  margin-top: 12px;
}
.buyCoinText {
  font-size: 15px;
  font-weight: bold;
  color: #0045ff;
  text-decoration: underline;
  cursor: pointer;
}
.buyItSty {
  margin-right: 10px;
}
.goBuyImgContainer {
  text-align: right;
}
.coinContainer {
  position: absolute;
  overflow: hidden;
  opacity: 1;
  animation: sideOut 0.5s forwards;
  -webkit-animation: sideOut 0.5s forwards;
  background-color: #fff;
}

@keyframes sideOut {
  0% {
    top: 25px;
  }
  100% {
    top: 840px;
  }
}

@-webkit-keyframes sideOut {
  0% {
    top: 25px;
  }
  100% {
    top: 840px;
  }
}

.coinContainerChoose {
  position: absolute;
  top: 20px;
  z-index: 9999;
  opacity: 1;
  animation: sideIn 0.5s forwards;
  -webkit-animation: sideIn 0.5s forwards;
  background-color: #fff;
}
@keyframes sideIn {
  0% {
    top: 784px;
  }
  100% {
    top: 25px;
  }
}

@-webkit-keyframes sideIn {
  0% {
    top: 784px;
  }
  100% {
    top: 25px;
  }
}
.cancelBuy {
  color: #0045ff;
  display: block;
  text-align: end;
  padding-right: 16px;
  background-color: #fff;
}
.cancelBuyText {
  cursor: pointer;
}
.noSupportContainer {
  display: flex;
  height: 332px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
