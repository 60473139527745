.payContainerFlash {
  width: 375px;
  max-width: 100%;;
}
.payCurrencyContainerFlash {
  border-radius: 20px;
}
.quickTop {
  text-align: center;
  margin-bottom: 80px;
}
.quickPassContainer {
  width: 315px;
}
.codeScanQuick {
  text-align: center;
  width: 200px;
  margin: auto;
  padding: 20px 0px;
  border: 1px solid rgba(0, 69, 255, 0.3);
  border-radius: 10px;
}
.quickPassImg {
  width: 60px;
  margin-right: 30px;
}
.quickPassBottom {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.quickPassBottom p {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
