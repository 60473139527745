.defaultLayout {
  max-width: 375px;
  margin: auto;
  padding: 48px 0 10px;
}
.headerContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.headerLeft {
  font-size: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
}
.headerLeft .title {
  font-size: 18px;
  color: #0045ff;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.logoImage {
  height: 32px;
}
.headerRight {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  color: #0045ff;
  cursor: pointer;
  text-decoration: underline;
}

.headerRightImg {
  margin-right: 5px;
}

.defaultFooter {
  max-width: 375px;
  margin: 10px auto 30px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.footerContainer {
  padding: 0 10px;
}
.footerLink {
  cursor: pointer;
}
.footerContainerRight {
  padding: 0 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.footerContainerRight .top {
  width: 100px;
  display: flex;
  justify-content: end;
  justify-content: flex-end;
  flex: 1;
}
.language-select {
  width: 100px;
  height: 40px;
  border: 1px solid rgba(64, 71, 214, 0.06);
  border-radius: 5px;
  padding: 0 15px;
  text-align: center;
}
.languageSty {
  display: block;
  width: 24px;
  height: 24px;
}
.languageText {
  margin-left: 4px;
}
.footerContainerRight .tips {
  width: 100px;
  margin-top: 10px;
  border: 1px solid rgba(0, 69, 255, 0.06);
  border-radius: 5px;
  box-shadow: 0 0 25px #1817171f;
  display: none;
  position: absolute;
  top: 24px;
    left: 28px;
}
.footerContainerRight .tips ul {
  padding: 0;
  text-align: center;
  margin-bottom: 0;
}
.footerContainerRight .tips ul li {
  list-style: none;
  height: 30px;
  line-height: 30px;
}
.footerContainerRight .tips ul li:hover {
  background-color: #f5f6f7;
  color: #48a3ff;
}
.footerContainerRight:hover .tips {
  display: block;
  width: 98px;
}
