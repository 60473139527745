.KycCenterContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .KycCenterContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}
.KycCenterContainer .titleTips{
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  opacity: 0.4;
}
.KycCenterContainer .textTips{
  display: flex;
  height: 38px;
  background: rgba(255, 142, 0, 0.1);
  align-items: center;
  margin-top: 20px;
  padding: 10px;
}

.KycCenterContainer .textTips img{
  width:10px;
  margin-right: 10px;
}

.KycCenterContainer .textStyContainer{
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.KycCenterContainer .textSty{
  width: 100%;
  position: absolute;
  display: flex;
  color: #FF8E00;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  white-space:nowrap;
  animation: textRoll 10s linear infinite normal;
}

@keyframes textRoll {
  0% {
    left:0;
  }
  100% {
    left:-100%;
  }
}

.KycCenterContainer .levelSty{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.KycCenterContainer .levelSty .levelLeft{
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  opacity: 0.6;
}

.KycCenterContainer .levelSty .levelRight{
  display: flex;
  align-items: center;
}

.KycCenterContainer .levelSty .levelRight .levelRightText{
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FAA72C;
  margin-left: 10px;
}

.KycCenterContainer .levelContainer{
  margin-top: 10px;
  padding: 10px 20px;
  background: rgba(0, 69, 255, 0.03);
  border-radius: 20px;
  min-height:150px;
}

.KycCenterContainer .levelContainer .containerTop{
  display: flex;
  align-items: center;
}

.KycCenterContainer .levelContainer .containerTop .starIconSty{
  margin-right: 9px;
  width: 30px;
  height: 30px;  
}

.KycCenterContainer .levelContainer .containerTop .levelTitle{
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-right: 10px;
}

.KycCenterContainer .levelContainer .containerTop .levelTips{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:#000
}

.KycCenterContainer .levelContainer .containerCenter{
  margin-top: 15px;
}

.KycCenterContainer .levelContainer .containerCenter .centerList{
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
}

.KycCenterContainer .levelContainer .containerCenter .centerList .left{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
  margin-right: 10px;
}

.KycCenterContainer .levelContainer .containerCenter .centerList .right{
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0045FF;
  margin-top: 8px;
}

.KycCenterContainer .goToBtn {
  border-radius: 8px;
  height: 40px;
  width: 160px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.KycCenterContainer .goToBtnDisabled {
  border-radius: 8px;
  background-color: #f5f5f5;
  height: 40px;
  width: 160px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
