.cardManagementContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}

@media screen and (max-width: 750px) {
  .cardManagementContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}

.cardManagementContainer .titleTips{
  margin: 10px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  mix-blend-mode: normal;
  opacity: 0.4;
}

.cardManagementContainer .cardListContainer{
  min-height: 320px;
}

.cardManagementContainer .cardListNoContainer{
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
}

.cardManagementContainer .cardListNoContainer .noCardImg{
  width: 72px;
}
.cardManagementContainer .cardContainer{
  padding: 12px;
  background: #fafafa;
  margin: 12px 0;
  border-radius: 12px;
}

.cardManagementContainer .cardTop{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.cardManagementContainer .cardTop .cardTopContainerLeft{
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.cardManagementContainer .cardTop .cardTopContainerLeft img{
  width: 36px;
  margin-right: 12px;
}

.cardManagementContainer .cardTop .cardTopContainerRight img{
  cursor: pointer;
  width: 16px;
}

.cardManagementContainer .cardBottom{
  display: flex;
}

.cardManagementContainer .cardBottom .cardBottomNumber{
  font-weight: 500;
  font-size: 16px;
  margin-right: 4px;
}

.cardManagementContainer  .addContainer .goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}