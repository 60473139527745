.payContainer {
  display: flex;
  max-width: 415px;
  margin: auto;
  border-radius: 20px;
  background-color: #f8fafa;
  box-shadow: 0px 40px 40px 40px rgb(0 69 255 / 6%);
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .payContainer-embedded{
    box-shadow:none
  }
}
.payContainer .inforContainerTitle{
  display: flex;
}
.inforContainer .cardTypeBankCard{
  display: flex;
  justify-content: space-between;
  margin: 20px 12px 20px 0;
}
.payContainer .inforContainerTitle .jianSty{
  width:16px;
  cursor: pointer;
}
.payContainer .inforContainerTitle h2{
  margin: 0;
  margin-right: 10px;
}
.cardImgContainer {
  width: 100px;
}
.payCurrencyContainer {
  background-color: #fff;
  width: 375px;
  max-width: 100%;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.payCurrencyContainer .payCurrencyContainerTop{
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}
.payCurrencyContainer .payCurrencyContainerTop .amountRightCurrencyCloseImg{
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.payCurrencyContainer .payCurrencyContainerTop .amountTitle{
  margin-bottom: 0;
}
.payRightImg {
  width: 62px;
  display: block;
  margin: 33px auto;
}
.payRightImg2{
  width: 58px;
  display: block;
  margin: 36px auto;
}
@media screen and (max-width: 750px) {
  .payContainer {
    width: 375px;
    max-width: 100%;
    flex-direction: column;
  }
  .cardImgContainer {
    /* display: none; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
  .payCurrencyContainer {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .payRightImg {
    margin: 10px 0;
    margin-right: 20px;
  }
}
.payContainer .inforContainer{
  margin: 10px 0 20px;
}
.amountTitle {
  color: #0045ff;
}
.backCardNumber {
  margin-top: 10px;
  color: #888;
}
.backCardNumberRed {
  color: #c91919;
  margin-top: 20px;
}
.tipsFont {
  color: #c91919;
  margin-top: 10px;
}
.cardInputContainer {
  display: flex;
}
.cardItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 16px;
}
.tipsCard {
  color: #888;
}
.weightTips {
  font-weight: bold;
  color: black;
}
.am-list-item:not(:last-child) .am-list-line {
  border: none;
}
.am-list-item.am-input-item {
  border-bottom: 2px solid rgba(64, 71, 214, 0.06);
  padding-left: 0;
}
.cvvSize {
  margin: 10px auto;
  font-size: 12px;
}
.country-select {
  width: 100%;
  height: 28px;
  margin-top: 8px;
  border: none;
  border-bottom: 1px solid rgba(64, 71, 214, 0.06);
}
.country-select:hover {
  width: 100%;
  height: 28px;
  margin-top: 8px;
  border: none;
  border-bottom: 2px solid #0045ff;
}
.country-select:focus {
  width: 100%;
  height: 28px;
  margin-top: 16px;
  border: none;
  border-bottom: 2px solid #0045ff;
}
.bankInitBillingContainer {
  height: 0;
  overflow: hidden;
}
.bankBillingContainer {
  overflow: hidden;
  height: 350px;
  animation: showBank 1s;
}
.bankHideBillingContainer {
  height: 0;
  overflow: hidden;
  animation: hideBank 1s;
}
@keyframes showBank {
  from {
    height: 0px;
  }
  to {
    height: 350px;
  }
}
@keyframes hideBank {
  from {
    height: 350px;
  }
  to {
    height: 0px;
  }
}
.field-container-phone {
  display: flex;
  flex-direction: row;
}
.field-container-phone .country-select-air-rel {
  width: 100px;
  height: 46px;
  margin-top: 8px;
  border: 1px solid rgba(64, 71, 214, 0.06);
  border-radius: 5px;
  padding: 0 15px;
  margin-right: 10px;
}
.field-container-phone .country-select-air-rel:hover {
  width: 100px;
  height: 46px;
  margin-top: 8px;
  border: 1px solid;
  border-radius: 5px;
}
.field-container-phone .am-list-item.am-input-item {
  flex: 1;
}
