.ChooseBindCardContainer {
  padding: 20px 15px;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 220px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .ChooseBindCardContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}

.ChooseBindCardContainer .container{
  margin-top: 12px;
}


.ChooseBindCardContainer .container .topTips{
  background: #fafafa;
  padding: 12px 0;
  color: #000;
  font-weight: 700;
  display: flex;
  font-size: 16px;
}
.ChooseBindCardContainer .container .topTips .left{
  flex: 1;
  text-align: center;
}

.ChooseBindCardContainer .container .topTips .left div{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChooseBindCardContainer .container .topTips .left div img{
  margin-right: 8px;
  height: 20px;
}

.ChooseBindCardContainer .container .topTips .center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChooseBindCardContainer .container .topTips .center img{
  height: 24px;
}

.ChooseBindCardContainer .container .topTips .right{
  flex: 1;
  text-align: center;
}

.ChooseBindCardContainer .container .topTips .right div{
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChooseBindCardContainer .container .topTips .right div img{
  margin-right: 8px;
  height: 20px;
}

.ChooseBindCardContainer .radioContainer{
  width: 100%;
}

.ChooseBindCardContainer .radioContainer .tips_container{
  width: 100%;
  display: flex;
  margin-top: 12px;
}

.ChooseBindCardContainer .radioContainer .tips_container img{
  width: 24px;
  margin-right: 4px;
}

.ChooseBindCardContainer .radioContainer .tips_container span{
  font-size: 12px;
  opacity: 0.3;
}

.ChooseBindCardContainer .payWith {
  color: #000;
  text-align: left;
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.6;
}

.ChooseBindCardContainer .bottomCardContainer {
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
}

.ChooseBindCardContainer .cardContainer{
  min-height: 300px;
  min-height: 40px;
}

.ChooseBindCardContainer .bottomCardContainer .radioContainer{
  width: 100%;
}

.ChooseBindCardContainer .bottomCardContainer .title{
  color: #000;
  text-align: left;
  margin-bottom: 4px;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem{
  background: #fafafa;
  border-radius: 10px;
  margin-bottom: 12px;
  border-bottom: none;
  cursor: pointer;
  padding: 12px;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem .ant-list-item-meta {
  align-items: center;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem .renderItemImg{
  height: 36px;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem .renderItemTitle{
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem .walletRenderItemTitle{
  font-size: 14px;
}
.ChooseBindCardContainer .bottomCardContainer .renderItem .walletRenderItemTitleTips{
  font-size: 14px;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem .walletRenderItemTitleTipsBottom{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.4;
}

.ChooseBindCardContainer .bottomCardContainer .renderItem .renderItemTitleTips{
  font-size: 14px;
  height: 36px;
  line-height: 36px;
}

.ChooseBindCardContainer .goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.ChooseBindCardContainer .goToBtnDisabled {
  border-radius: 8px;
  background-color: #f5f5f5;
  height: 48px;
  width: 100%;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.ChooseBindCardContainer .chooseCardTips {
  font-size: 12px;
  color: #0045ff;
  text-decoration: underline;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.walletLabel label{
  height: 48px;
}

.walletGoToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.walletConfirmGoToBtnContainer{
  width:100%;
  text-align: center;
}

.walletConfirmGoToBtnContainer .walletConfirmGoToBtn{
  border-radius: 8px;
  height: 48px;
  width: 280px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.ChooseBindCardContainer .checkContainer{
  font-size: 12px;
}
.ChooseBindCardContainer .checkContainer .checkSty{
  margin-right: 8px;
}
.ChooseBindCardContainer .checkContainer a{
  color:#0045ff;
  font-weight: 500;
}

.ChooseBindCardContainer .showEmailTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
  height: 20px;
  margin-bottom: 0;
}

.ChooseBindCardContainer .showEmailTips .showEmailTipsContainer{
  display: flex;
  align-items: center;
}

.ChooseBindCardContainer .showEmailTips img{
  width: 18px;
  margin-right: 4px;
}
.ChooseBindCardContainer .showEmailTips span{
  line-height: 14px;
}

.ChooseBindCardContainer .radioContainer .bind_list {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: center;
}

.ChooseBindCardContainer .radioContainer .bind_list .bind_list_title {
  font-weight: 400;
  font-size: 12px;
  margin-right: 8px;
}
.ChooseBindCardContainer .radioContainer .bind_list img {
  cursor: pointer;
}

