@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input:focus, .ant-input-focused{
  border-color:#0045ff
}

.ant-input:hover{
  border-color: #0045ff
}

.ant-btn-primary{
  background-color: #0045ff;
}

.ant-input:hover {
  border-color: #0045ff;
  border-right-width: 1px;
}

.ant-input:focus, .ant-input-focused{
  border-color: #0045ff;
  border-right-width: 1px;
}

.ant-btn-primary{
  background-color: #0045ff;
}

.ant-btn-primary{
  background-color: #0045ff;
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover{
  border-color:#0045ff;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #0045ff;
  border-right-width: 1px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: #0045ff;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #0045ff;
}

.ant-input{
  border-radius: 10px;
  height:48px;
}

.ant-input:hover{
  border: 1px solid #0045ff;
}

.ant-btn-primary{
  background-color: #0045ff;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: #0045ff;
  border-color: #0045ff;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  border: 1px solid #0045ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
  border: 1px solid #0045ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
  background: #0045ff;
}

.ant-steps-item-process .ant-steps-item-icon{
  border-color: #0045ff;
}