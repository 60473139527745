.KycContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .KycContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}
.KycContainer .titleContainer {
  display: flex;
  justify-content: space-between;
}
.KycContainer .titleContainerLeft{
  font-size: 0;
}
.KycContainer .titleContainerLeft img{
  width: 16px;
  vertical-align:middle;
  margin-right: 8px;
  cursor: pointer;
}

.KycContainer .titleContainerLeft .title{
  font-size: 24px;
  vertical-align:middle;
  height: 24px;
  line-height: 24px;
  font-weight: 400;
}

.KycContainer .titleContainerRight img{
  width: 28px;
  vertical-align:middle;
  cursor: pointer;
}
.KycContainer .tipsContainer{
  margin-top: 24px;
  /* margin-bottom: 36px; */
  font-weight: 500;
}

.KycContainer .am-list-item.am-input-item{
  border:1px solid #d9d9d9;
  border-radius: 11px;
}

.KycContainer .am-list-item.am-input-item .am-list-line{
  padding-left: 10px;
}

.KycContainer .verConfirm{
  margin-top: 48px;
  cursor: pointer;
  color: #fff;
  background-color: #0045ff;
  width: 100%;
}

.KycContainer .inputContainer{
  display: flex;
  margin-bottom: 24px;
}

.KycContainer .inputContainer .inputContent{
  flex: 1;
}

.KycContainer .inputContainer .inputContent .uploadDescribe{
  font-size: 14px;
}

/* .KycContainer .inputContainer .inputContent .ant-input-lg{
  font-size:12px;
} */

.KycContainer .inputContainer .ant-select .ant-select-selector{
  height:48px
}

.KycContainer .inputContainer .ant-select .ant-select-selector .ant-select-selection-item{
  height:48px;
  line-height: 48px;
}

.KycContainer .inputContainer .ant-select .ant-select-selector .ant-select-selection-placeholder{
  font-weight: normal;
  line-height: 46px;
  padding-top: 0;
}

/* .KycContainer .inputContainer .ant-select .ant-select-selector .ant-select-selection-search-input{
  height:48px
} */

/* .KycContainer .inputContainer .ant-input{
  border-radius: 10px;
} */

/* .KycContainer .inputContainer .ant-input:hover{
  border: 1px solid #0045ff;
} */

/* .ant-picker:hover, .ant-picker-focused {
  border-color: #0045ff;
  border-right-width: 1px;
} */

/* .KycContainer .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
  border-color: #0045ff;
} */

/* .KycContainer .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #0045ff;
} */

.KycContainer .inputContainer .am-list-item.am-input-item .am-list-line{
  padding-left: 10px;
}

.KycContainer .inputContainer .inputContentLeft{
  margin-right: 8px;
  max-width: 50%;
}

.KycContainer .inputContainer .inputContent .inputTitle{
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.KycContainer .inputContainer .inputContent .am-list-item .am-input-control input{
  font-size: 14px;
}
.KycContainer  .inputContainer .selectContainer {
  flex:1;
  height: 40px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0 15px;
  margin-right: 10px;
}

.KycContainer  .inputContainer .selectCountryContainer{
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
}

.KycContainer  .inputContainer .ant-select-selector{
  border-radius: 10px;
}

.KycContainer  .inputContainer .selectIdATypeContainer{
  width: 100%;
  flex:1;
  border-radius: 10px;
}

.KycContainer .uploadBtn {
  margin-left: 10px;
  border-radius: 4px;
  font-size: 0;
  text-align: center;
  background: #0045ff;
  padding: 0 4px;
  color: #fff;
}

.KycContainer .uploadBtn span {
  font-size: 14px;
}

.KycContainer .uploadBtn img {
  height: 24px;
  margin-left: 10px;
}

.KycContainer .idCardContainer {
  width: calc(50% - 6px);
  height: 80px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 10px;
}

.KycContainer .idCardContainer img {
  height: 60px;
  margin-top: 10px;
}

.KycContainer .idCardSelfieContainer{
  width: 100%;
  height: 160px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 10px;
}

.KycContainer .idCardSelfieContainer img {
  height: 140px;
  margin-top: 10px;
}

.KycContainer .inputContainer .topContainer .stepContainer{
  margin-top: 12px;
  background: #f5f5f5;
  padding: 12px;
  border-radius: 4px;
}
.KycContainer .inputContainer .topContainer .tipsContainer{
  margin-top: 24px;
}
.KycContainer .inputContainer .topContainer .leftMsg .leftMsgTitle{
  font-weight: 500;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
}
.KycContainer .inputContainer .topContainer .leftMsg .leftMsgTips{
  display: flex;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}
.KycContainer .inputContainer .topContainer .leftMsg .leftMsgTipsSContainer{
  margin-top: 12px;
}
.KycContainer .inputContainer .topContainer .leftMsg .leftMsgTipsS{
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  opacity: 0.5;
}
.KycContainer .inputContainer .topContainer .tipsContact{
  font-weight: 500;
  color: #000;
  margin-top: 24px;
  font-size: 14px;
  line-height: 16px;
}
.KycContainer .inputContainer .topContainer .tipsContact a{
  color:#0045ff;
  margin-left: 4px;
}
.KycContainer .inputContainer .topContainer .submitContainer{
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.KycContainer .stepContainer .am-steps-item-title{
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}