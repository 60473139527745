.FastBuyContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 220px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .FastBuyContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}

.FastBuyContainer .titleContainer {
  margin-bottom: 10px;
}

.FastBuyContainer .wrapper-left-item {
  margin-bottom: 20px;
}

.FastBuyContainer .wrapper-left-item-last {
  /* height: 112px; */
  margin-bottom: 0;
}

.FastBuyContainer .wrapper-left-item-last .rateTips {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.FastBuyContainer .wrapper-left-item-last .rateTips .rateTipsList{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.FastBuyContainer .wrapper-left-item-last .rateTips .rateTipsList .rateTipsListLeft{
  opacity: 0.3;
}

.FastBuyContainer .wrapper-left-item-last .rateTips .rateTipsList .rateTipsListRight{
  opacity: 0.6;
}

.FastBuyContainer .errorTipsContent {
  /* height: 40px; */
}

.FastBuyContainer .wrapper-left-item .triangle {
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: transparent transparent rgba(230, 154, 58, 0.12) transparent;
  width: 0px;
  height: 0px;
  margin-left: 10px;
}

.FastBuyContainer .wrapper-left-item .maxTips {
  background: rgba(230, 154, 58, 0.12);
  padding: 5px;
  border-radius: 5px;
}

.FastBuyContainer .wrapper-left-item-last .rateTips .rateTipsTitle {
  opacity: 0.3;
}

.FastBuyContainer .powerContainer{
  text-align: center;
  color: #8c8c8c;
  font-size: 12px;
}

.FastBuyContainer .powerContainer img{
  width: 40px;
  margin-left: 8px;
}

.FastBuyContainer .wrapper-left-item-last .rateTipsLeft {
  color: #000;
  font-size: 14px;
  text-align: left;
  background: rgba(230, 173, 20, 0.05);
  display: flex;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.FastBuyContainer .wrapper-left-item-last .rateTipsLeft img {
  width: 24px;
  height: 24px;
}

.FastBuyContainer .wrapper-left-item-last .rateTipsLeft .rateTipsTitleLeft {
  opacity: 0.8;
  margin-left: 4px;
}

.FastBuyContainer .select-container {
  display: flex;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 15px;
}

.FastBuyContainer .select-container:hover {
  border: 1px solid #0045ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  transition: all 0.3s;
}

.FastBuyContainer .select-container:focus-within {
  border: 1px solid #0045ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  transition: all 0.3s;
}

.FastBuyContainer .select-container .ant-input {
  width: 200px;
  font-size: 15px;
  height: 42px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  font-weight: bold;
}

.FastBuyContainer .ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}

.FastBuyContainer .select-container .ant-select {
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 42px;
  flex: 1;
  border: 0;
}

.FastBuyContainer .select-container .ant-select .ant-select-selector {
  height: 42px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 0;
  border: 0;
}

.FastBuyContainer .select-container .ant-select .ant-select-selector {
  box-shadow: none !important;
}
.FastBuyContainer
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 6px;
  font-weight: bold;
}
.FastBuyContainer
  .select-container
  .ant-select
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 42px;
}

.FastBuyContainer .wrapper-left-item .select-label {
  /* margin-top: 10px; */
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
}

.FastBuyContainer .addressTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
  height: 20px;
  margin-bottom: 0;
}

.FastBuyContainer .verConfirm {
  cursor: pointer;
  color: #fff;
  background-color: #0045ff;
  width: 100%;
}

.FastBuyContainer .goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.FastBuyContainer .goToBtnDisabled {
  border-radius: 8px;
  background-color: #f5f5f5;
  height: 48px;
  width: 100%;
  margin: 25px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}

.blankListContainer .tipsContact {
  font-weight: 500;
  color: #000;
  font-size: 14px;
  line-height: 16px;
}

.blankListContainer .wrapper-left-risk{
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.blankListContainer .wrapper-left-risk .tips_img{
  margin-right: 8px;
}

.blankListContainer .wrapper-left-risk .risk-text{
  flex: 1;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-title-tips{
  font-weight: 500;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-tips-weight{
  font-weight: 500;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-title{
  font-size: 16px;
  font-weight: 500;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-tips{
  margin-top: 8px;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-tips-img{
  text-align: center;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-tips img{
  width: 240px;
}

.blankListContainer .wrapper-left-risk .risk-text .risk-tips-weight{
  color: #000;
}

.blankListContainer .risk-bottom {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.blankListContainer .risk-bottom .risk-button {
  width: 120px;
  height: 40px;
  font-weight: bold;
  border-radius: 8px;
}