.loginContainer {
  padding: 20px 0;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 200px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .loginContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}
.loginContainer .am-result {
  padding: 0;
}
.loginContainer .am-result-title {
  font-size: 24px;
  font-weight: bold;
}
.loginContainer .am-button-primary {
  background-color: #0045ff;
  cursor: pointer;
  border-radius: 10px;
}
.loginContainer .spe {
  width: 60px;
  height: 60px;
}

.loginContainer .top {
  margin-top: 5vmin;
  color: #000;
}

.loginContainer .enterEmailContainer {
  text-align: left;
  color: #000;
  margin: 48px 0 120px;
}

.loginContainer .enterEmailContainer p {
  margin: 5px 0;
}

/* .loginContainer .enterEmailContainer .ant-input{
  border-radius: 10px;
} */

/* .loginContainer .enterEmailContainer .ant-input:hover{
  border: 1px solid #0045ff;
} */

.loginContainer .enterEmailContainer .am-list-item.am-input-item .am-list-line {
  padding-left: 10px;
}
