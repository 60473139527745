.walletHistoryDetailsContainer {
  padding: 20px 18px;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  min-height: 500px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .walletHistoryDetailsContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}

.walletHistoryDetailsContainer .titleContainer{
  margin-bottom: 30px;
}

.walletHistoryDetailsContainer .detailsList{
  display: flex;
  justify-content: space-between;
}

.walletHistoryDetailsContainer .detailsList .title{
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
}

.walletHistoryDetailsContainer .detailsList .addressCopy{
display: flex;
}

.walletHistoryDetailsContainer .detailsList .addressCopy .copyImage{
  cursor: pointer;
  width:32px;
}

.walletHistoryDetailsContainer.detailsList .title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 6px;
}

.walletHistoryDetailsContainer .detailsContent .detailsList .tips{
  font-size: 14px;
  font-weight: 500;
}

.walletHistoryDetailsContainer .detailsList .addressCopy .tips{
  flex:1;
  line-height: 32px;
  overflow:hidden; 
  text-overflow:ellipsis;
  white-space:nowrap;
}
.walletHistoryDetailsContainer .detailsList .tipsLink{
  color:#0045ff;
  cursor: pointer;
}

.walletHistoryDetailsContainer .bottomTips{
  font-size: 12px;
  font-weight: 500;
  opacity: 0.8;
  margin-bottom: 12px;
}

.deposit_container .tips_container{
  width: 100%;
  background: rgba(250, 179, 30, 0.1);
  border-radius: 20px;
  padding: 30px 12px;
  margin-bottom: 30px;
}

.deposit_container .tips_container .tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ff8e00;
  margin-bottom: 30px;
}

.deposit_container .tips_container .tips_btn_container{
  display: flex;
  justify-content: space-between;
}

.deposit_container .tips_container .tips_btn_container .tips_btn{
  display: flex;
}

.deposit_container .tips_container .tips_btn_container .tips_btn_right{
  cursor: pointer;
}

.deposit_container .tips_container .tips_btn_container .tips_btn .tips_w{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 12px;
}

.deposit_container .tips_container .tips_btn_container .tips_btn .tips_g {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ff8e00;
  margin-right: 10px;
}

.deposit_container .wrapper-left-item {
  margin-bottom: 20px;
}

.deposit_container .select-container {
  display: flex;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 15px;
}

.deposit_container .select-container:hover {
  border: 1px solid #0045ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  transition: all 0.3s;
}

.deposit_container .select-container:focus-within {
  border: 1px solid #0045ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  transition: all 0.3s;
}

.deposit_container .select-container .ant-input {
  width: 200px;
  font-size: 15px;
  height: 42px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  font-weight: bold;
}

.deposit_container .ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}

.deposit_container .select-container .ant-select {
  border-radius: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 42px;
  flex: 1;
  border: 0;
}

.deposit_container .select-container .ant-select .ant-select-selector {
  height: 42px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 0;
  border: 0;
}

.deposit_container .select-container .ant-select .ant-select-selector {
  box-shadow: none !important;
}
.deposit_container
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 6px;
  font-weight: bold;
}
.deposit_container
  .select-container
  .ant-select
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 42px;
}

.deposit_container .wrapper-left-item .select-label {
  /* margin-top: 10px; */
  margin-bottom: 10px;
  font-size: 14px;
  text-align: left;
}

.deposit_container .wrapper-left-item-last {
  /* height: 112px; */
  margin-bottom: 0;
}

.deposit_container .wrapper-left-item-last .rateTips {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.deposit_container .wrapper-left-item-last .rateTips .rateTipsList{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.deposit_container .wrapper-left-item-last .rateTips .rateTipsList .rateTipsListLeft{
  opacity: 0.3;
}

.deposit_container  .wrapper-left-item .table-container{
  width:100%;
  overflow: scroll;
}

.creditDepositContainer {
  padding: 20px 18px;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  min-height: 500px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .creditDepositContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}

.creditDepositContainer .deposit_credit_container{
  margin-top: 12px;
}

.creditDepositContainer .deposit_credit_container h3{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-top: 12px;
}

.creditDepositContainer .deposit_credit_container .deposit_steps{
  display: flex;
  margin: 8px 0;
}

.creditDepositContainer .deposit_credit_container .deposit_steps img{
  width: 16px;
  margin-right: 10px;
}

.creditDepositContainer .deposit_credit_container .deposit_steps span{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.4);
}

.creditDepositContainer .deposit_credit_container .tips_container{
  width: 100%;
  border-radius: 8px;
  background: rgba(0, 69, 255, 0.05);
  padding: 12px;
  margin: 30px 0;
}

.creditDepositContainer .deposit_credit_container .tips_container .tips_line{
  margin: 12px 0;
  display: flex;
  justify-content: flex-end;
}

.creditDepositContainer .deposit_credit_container .tips_container .tips_line .tips_line_left {
  margin-right: 24px;
  width: 120px;
  opacity: 0.6;
}

.creditDepositContainer .deposit_credit_container .tips_container .tips_line .tips_line_right {
  font-weight: 500;
  width: 120px;
  text-align: right;
}

.creditDepositContainer .deposit_credit_container .tips_container h4{
  margin: 40px 0 10px 0;
  color: rgba(0, 0, 0, 0.6);
}

.creditDepositContainer .deposit_credit_container .tips_container .tips_bottom {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0045ff;
}

.creditDepositContainer .deposit_credit_container .tips_container .title_tips {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ff8e00;
  margin-bottom: 10px;
}

.creditDepositContainer .deposit_credit_container .tips_container .title_tips .tips {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 5px;
}

.creditDepositContainer .deposit_credit_container .tips_container .tips_container_center {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.creditDepositContainer .deposit_credit_container .tips_container .tips_container_center h3 {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.creditDepositContainer .deposit_credit_container .tips_container_tips {
  background: rgba(250, 179, 30, 0.1);
  color: #ff8e00;
}

.creditDepositContainer .deposit_credit_container .confirm_container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.creditDepositContainer .deposit_credit_container .confirm_container .confirm-btn {
  height: 40px;
  width: 120px;
}

.creditDepositContainer .deposit_credit_container .info_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 10px;
}

.creditDepositContainer .deposit_credit_container  .deposit_bank_msg_container {
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 0px 0 20px;
  background: rgba(0, 69, 255, 0.03);

}

.creditDepositContainer .deposit_credit_container .deposit_bank_msg_container_left {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.creditDepositContainer .deposit_credit_container .deposit_bank_msg_container_left .deposit_bank_msg_container_line {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 10px 0;
}

.creditDepositContainer .deposit_credit_container .deposit_bank_msg_container_left .deposit_bank_msg_container_line .line_left {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.creditDepositContainer .deposit_credit_container .deposit_bank_msg_container_left .deposit_bank_msg_container_line .line_right {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
}

.creditDepositContainer .deposit_credit_container .deposit_bank_msg_container_left .deposit_bank_msg_container_line .line_right .copyBtn {
  margin-left: 10px;
}

.creditDepositContainer .deposit_credit_container .attention_tips{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff8e00;
  margin: 12px 0 40px;
}

.creditDepositContainer .deposit_credit_container .rateTipsLeft {
  color: #000;
  font-size: 14px;
  text-align: left;
  background: rgba(230, 154, 58, 0.12);
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
}

.creditDepositContainer .deposit_credit_container .rateTipsLeft img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.creditDepositContainer .deposit_credit_container .rateTipsLeft .rateTipsTitleLeft {
  opacity: 0.8;
  margin-left: 4px;
  font-size: 12px;
}