.VerificationContainer {
  padding: 20px 15px;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 220px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .VerificationContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}
.VerificationContainer .am-list-item .am-input-control input {
  font-size: 14px;
}
.VerificationContainer .titleContainer {
  display: flex;
  justify-content: space-between;
}
.VerificationContainer .titleContainerLeft {
  font-size: 0;
}
.VerificationContainer .titleContainerLeft img {
  width: 16px;
  vertical-align: middle;
  margin-right: 8px;
  cursor: pointer;
}

.VerificationContainer .titleContainerLeft .title {
  font-size: 24px;
  vertical-align: middle;
  height: 24px;
  line-height: 24px;
  font-weight: 400;
}

.VerificationContainer .titleContainerRight img {
  width: 28px;
  vertical-align: middle;
  cursor: pointer;
}
.VerificationContainer .tipsContainer {
  margin-top: 24px;
  margin-bottom: 36px;
  font-weight: 500;
}

.VerificationContainer .am-list-item.am-input-item {
  border: 1px solid #0045ff;
  border-radius: 10px;
}

.VerificationContainer .am-list-item.am-input-item .am-list-line {
  padding-left: 10px;
}

.VerificationContainer .showEmailTips {
  color: #c91919;
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
  height: 20px;
  margin-bottom: 0;
}

.VerificationContainer .showEmailTips .showEmailTipsContainer{
  display: flex;
  align-items: center;
}

.VerificationContainer .showEmailTips img{
  width: 18px;
  margin-right: 4px;
}
.VerificationContainer .showEmailTips span{
  line-height: 14px;
}

.VerificationContainer .resendContainer {
  text-align: center;
  margin-bottom: 150px;
}

.VerificationContainer .resendContainer .tipsLeft {
  font-size: 14px;
  color: #000;
  opacity: 0.5;
  margin-right: 4px;
}

.VerificationContainer .resendContainer .tipsRight {
  font-size: 16px;
  color: #0045ff;
  font-weight: 500;
  cursor: pointer;
}

.VerificationContainer .resendContainer .resendEmailDisabled {
  opacity: 0.5;
}
.VerificationContainer .checkContainer{
  margin-top: 12px;
  margin-bottom: 10px;
}
.VerificationContainer .checkContainer .checkSty{
  margin-right: 8px;
}
.VerificationContainer .checkContainer a{
  color:#0045ff;
  text-decoration: underline;
}
.VerificationContainer .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0045ff;
}
.VerificationContainer .ant-checkbox-checked::after{
  border:#0045ff;
}
.VerificationContainer .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0045ff;
  border-color: #0045ff;
}
.VerificationContainer .verConfirm {
  width: 100%;
  border-radius: 10px;
  height: 48px;
}
