
.titleContainer {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
}
.titleContainer .titleContainerLeft{
  font-size: 0;
}
.titleContainer .titleContainerLeft img{
  width: 16px;
  vertical-align:middle;
  margin-right: 8px;
  cursor: pointer;
}

.titleContainer .titleContainerLeft .title{
  font-size: 18px;
  vertical-align:middle;
  height: 28px;
  line-height: 28px;
  font-weight: 700;
}

.titleContainer .titleContainerRight img{
  width: 28px;
  vertical-align:middle;
  cursor: pointer;
}
