.historyContainer {
  padding: 20px 0;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 220px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .historyContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}

.historyContainer .titleContainer {
  margin-bottom: 12px;
  padding: 0 18px;
}

.historyContainer .tipsAnimeContainer{
  display: flex;
  font-size: 0;
  background: rgba(250, 179, 30, 0.2);
  padding: 10px 22px;
}

.historyContainer .tipsAnimeContainer img{
  width: 18px;
  vertical-align: middle;
  margin-right: 8px;
}

.historyContainer .tipsAnimeContainer .tipsText{
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #FF8E00;
}

.historyContainer .listContent {
  height: 420px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-y: auto;
  padding: 0 18px;
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.historyContainer .titleContainer .titleContainerLeft {
  max-width: 280px;
  overflow: hidden;
}

.historyContainer .listContainer {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  cursor: pointer;
  padding: 0 4px;
}

.historyContainer .listContainer:hover {
  background-color: rgba(64, 71, 214, 0.06);
}

.historyContainer .listContainer .listLeft {
  display: flex;
  font-size: 0;
}

.historyContainer .listContainer .listLeft img {
  width: 24px;
  vertical-align: middle;
  margin-right: 8px;
}

.historyContainer .listContainer .listLeft .listLeftTitle {
  display: flex;
  flex-direction: column;
}

.historyContainer .listContainer .listLeft .listLeftTitle .listLeftTitleTop {
  font-size: 14px;
  font-weight: 500;
}

.historyContainer .listContainer .listLeft .listLeftTitle .listLeftTitleBottom {
  font-size: 10px;
  opacity: 0.5;
}

.historyContainer .listContainer .listLeft span {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle;
}

.historyContainer .listContainer .listRight img {
  width: 24px;
  vertical-align: middle;
}

.historyContainer .Btn {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  margin-top: 20px;
}

/* .historyContainer .ant-btn-primary{
  background-color: #0045ff;
} */

.historyContainer .pageSty {
  text-align: end;
}

/* .ant-pagination-simple .ant-pagination-simple-pager input:hover{
  border-color:#0045ff;
} */

/* .ant-input{
  border-radius: 10px;
} */

/* .ant-input:hover{
  border: 1px solid #0045ff;
} */
