.sub-title {
  color: #888;
  font-size: 14px;
  padding: 30px 0 18px 0;
  margin-left: 15px;
}
.am-result::after {
  height: 0 !important;
}

.messageNodeLink {
  color: #0045ff;
  margin-bottom: 10vmin;
  text-decoration: underline;
}
.topMessage {
  margin-top: 5vmin;
}
.am-result {
  border-bottom: none;
}
.result-example .spe {
  width: 60px;
  height: 60px;
}
.am-result .am-result-button {
  margin-top: 5vmin;
}
