.payContainer {
  display: flex;
  max-width: 475px;
  margin: auto;
  border-radius: 20px;
  background-color: #f8fafa;
  box-shadow: 0px 40px 40px 40px rgb(0 69 255 / 6%);
  justify-content: center;
}
@media screen and (max-width: 750px) {
  .payContainer-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}
.cardImgContainer {
  width: 100px;
}
.payCurrencyContainer {
  background-color: #fff;
  width: 375px;
  max-width: 100%;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.payRightImg {
  width: 62px;
  display: block;
  margin: 33px auto;
}
@media screen and (max-width: 750px) {
  .payContainer {
    max-width: 315px;
    flex-direction: column;
  }
  .cardImgContainer {
    /* display: none; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
  .payCurrencyContainer {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .payRightImg {
    margin: 10px 0;
    margin-right: 20px;
  }
}
.sepaFormContainer {
  margin-top: 20px;
}
.sepaFormContainer .deposit_bank_msg_container {
  display: block;
  padding: 20px 0px
}

.sepaFormContainer .deposit_bank_msg_container .deposit_bank_msg_container_line{
  /* display: block; */
  padding: 10px 20px
}

.cus-alert{
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
}
.cus-alert-content{

  box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 1);
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    word-wrap: break-word;
    border-radius: 2px;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    line-height: 22px;

}

.sepaFormContainer .deposit_bank_msg_container_line .line_left{
  opacity: .6;
}
.sepaFormContainer .deposit_bank_msg_container_line .line_right{
  font-weight: 500;
}