.result-example {
  padding-top: 100px;
  padding-bottom: 40px;
  width: 375px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
  margin: 0px auto;
}
@media screen and (max-width: 750px) {
  .result-example-embedded{
    box-shadow:none;
    max-width: 100%;
  }
}
.sub-title {
  color: #888;
  font-size: 14px;
  padding: 30px 0 18px 0;
  margin-left: 15px;
}
.am-result::after {
  height: 0 !important;
}

.messageNodeLink {
  color: #0045ff;
}
.topMessage {
  margin-top: 40px;
  margin-bottom: 80px;
  text-align: left;
}
.result-example .topMessage .tips{
  margin: 12px 0;
}

.result-example .topMessage .tipsContainer{
  border-radius: 8px;
  padding: 12px 18px;
  background-color: #f8f8f8;
}
.result-example .topMessage .tipsContainerNew{
  text-align: center;
}

.result-example .topMessage .tipsContainer .attention{
  display: flex;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

.result-example .topMessage .tipsContainer .attention img{
  width:24px;
  margin-right: 4px;
}

.result-example .topMessage .tipsContainer .attention .title{
  height:24px;
  line-height: 24px;
}

.result-example .spe {
  width: 60px;
  height: 60px;
}

.result-example .am-result {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.result-example .am-result .am-button-primary {
  background-color: #0045ff;
  cursor: pointer;
}

.result-example  .goToBtn {
  border-radius: 8px;
  height: 48px;
  width: 100%;
  margin: 10px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}