.currencyContainer-list {
  width: 365px;
  max-width: 100%;
  /* min-height: calc(100vh - 170px); */
  margin: auto;
  border-radius: 20px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
  position: relative;
}
@media screen and (max-width: 750px) {
  .currencyContainer-list-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}

.currencyContainer-list .currencyCloseImg {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.canvasContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottomBorder {
  height: 1px;
  background-color: #f0f0f0;
  box-shadow: 0px 40px 40px 0px rgba(0, 69, 255, 0.06);
  border-radius: 16px;
  margin-top: 10px;
}
.currencyCenter {
  margin-top: 40px;
  margin-bottom: 50px;
}
.listContainer-list {
  position: relative;
}
.listContainer .am-tabs-default-bar-tab-active {
  color: #0045ff;
  font-weight: bold;
}
.listContainer .am-tabs-default-bar-underline {
  border: 1px #0045ff solid;
}
.listContainer .am-tabs-default-bar-top .am-tabs-default-bar-tab {
  font-weight: bold;
}
.topRight {
  font-weight: bold;
}
.topRightAmount {
  margin-right: 5px;
  color: #0045ff;
  font-size: 20px;
}
.currencyTop {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-top: 20px;
}
.centerTop {
  margin-bottom: 20px;
}
.listItem {
  display: flex;
  min-height: 64px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #ddd;
}
.listItemContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.listItemTop {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.listItemBottomTips {
  margin-top: 16px;
  font-size: 12px;
  font-weight: 600;
}
.listItemBottomTipsContainer {
  display: flex;
  flex: 1;
  margin-top: 16px;
  align-items: center;
}
.listItemBottomTipsText {
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.6;
}
.listItemCardTipsText {
  margin-left: 24px;
  font-weight: 400;
  font-size: 12px;
}
.listItemChoose {
  display: flex;
  min-height: 64px;
  border-radius: 5px;
  border: 1px solid #0045ff;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.cardTips4 {
  width: 15px;
  height: 17px;
}
.centerBottom {
  position: absolute;
  bottom: 20px;
}
.listItemLeft {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.listItemLeftUsdtContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 5px;
}

.listItemLeftUsdt {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItemText {
  font-size: 12px;
  margin: 2px 0;
}
.listItemImg {
  height: 31px;
}
.listItemImgSEPA, .listItemImgPost {
  width: 48px;
  height: auto;
}

.listItemImgPayWay {
  height: 15px;
}
.listItemImgSmall {
  height: 23px;
  margin-left: 4px;
}
.listItemRightSmall {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}
.listItemLeftContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.listItemLeftContainer .am-list-thumb {
  margin-right: 0 !important;
}
.listItemLeftContainer .am-list-line {
  padding-right: 0 !important;
}
.listItemRight {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
}
.tipWeight {
  font-weight: bold;
  margin-right: 5px;
}
.tipReadMe {
  font-size: 12px;
  color: #0045ff;
}
.loadingDiv {
  text-align: center;
  padding: 180px 0;
}

.visaBtn {
  background-color: #fff;
  min-height: 86px;
  cursor: pointer;
  text-align: left;
  margin-bottom: 20px;
}

.visaBtnChoose {
  min-height: 86px;
  cursor: pointer;
  text-align: left;
  margin-bottom: 20px;
  border: 1px solid #0045ff;
}

.btnContainer {
  margin-top: 40px;
  height: 48px;
  cursor: pointer;
}

.confirmBtnBottom {
  color: #fff;
  background-color: #0045ff;
}

.visaImg {
  height: 86px;
}

.listItemLeftContainer .am-checkbox.am-checkbox-checked .am-checkbox-inner {
  border-color: #0045ff;
  background: #0045ff;
}
