.kycResultContainer {
  max-width: 365px;
  margin: 0px auto;
  min-height: 500px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .kycResultContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}
.kycResultContainer .kycResultTop {
  padding: 24px 15px;
}
.kycResultContainer .am-result-title {
  font-size: 24px;
  font-weight: bold;
}
.loginContainer .am-result {
  padding: 0;
}
.kycResultContainer .am-button-primary {
  background-color: #0045ff;
  cursor: pointer;
  border-radius: 10px;
}
.kycResultContainer .spe {
  width: 60px;
  height: 60px;
}

.kycResultContainer .top {
  color: #000;
}

.kycResultContainer .top p {
  font-size: 14px;
}

.kycResultContainer .enterEmailContainer {
  text-align: left;
  color: #000;
  margin: 48px 0 120px;
}

.kycResultContainer .enterEmailContainer p {
  margin: 5px 0;
}

.kycResultContainer .enterEmailContainer .am-list-item.am-input-item {
  border: 1px solid rgba(64, 71, 214, 0.06);
  border-radius: 10px;
}

.kycResultContainer
  .enterEmailContainer
  .am-list-item.am-input-item
  .am-list-line {
  padding-left: 10px;
}
