.settingContainer {
  padding: 20px 15px;
  padding-bottom: 40px;
  max-width: 365px;
  margin: 0px auto;
  /* min-height: calc(100vh - 220px); */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 40px 40px 40px rgba(0, 69, 255, 0.06);
}
@media screen and (max-width: 750px) {
  .settingContainer-embedded {
    box-shadow: none;
    max-width: 100%;
  }
}
.settingContainer .titleContainer {
  margin-bottom: 30px;
}

.settingContainer .titleContainer .titleContainerLeft {
  max-width: 280px;
  overflow: hidden;
}

.settingContainer .listContainer {
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  cursor: pointer;
}

.settingContainer .listContainer:hover {
  background-color: rgba(64, 71, 214, 0.06);
}

.settingContainer .listContainer .listLeft {
  font-size: 0;
}

.settingContainer .listContainer .listLeft img {
  width: 24px;
  vertical-align: middle;
  margin-right: 8px;
}
.settingContainer .listContainer .listLeft span {
  font-size: 16px;
  font-weight: 500;
  vertical-align: middle;
}

.settingContainer .listContainer .listRight {
  width: 100px;
  text-align: end;
}

.settingContainer .listContainer .listRight img {
  width: 24px;
  vertical-align: middle;
}

.settingContainer .Btn {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  margin-top: 20px;
}

/* .settingContainer .ant-btn-primary{
  background-color: #0045ff;
} */

.settingContainer .risk-bottom {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.settingContainer .risk-bottom .risk-button {
  width: 120px;
  height: 40px;
  font-weight: bold;
  border-radius: 8px;
}

.settingContainer .wrapper-left-risk .risk-text .risk-title-tips{
  font-weight: 500;
}

.settingContainer .wrapper-left-risk .risk-text .risk-tips-weight{
  font-weight: 500;
}